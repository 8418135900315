/* line 4, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5; }

@media screen and (min-width: 767px) {
  /* line 10, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
  html {
    font-size: 18px; } }

/* line 15, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
body {
  color: #515151;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/* No `:visited` state is required by default (browsers will use `a`) */
/* line 23, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
a {
  color: #268bd2;
  text-decoration: none; }

/* line 27, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
a strong {
  color: inherit; }

/* `:focus` is linked to `:hover` for basic accessibility */
/* line 31, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
a:hover,
a:focus {
  text-decoration: underline; }

/* Headings */
/* line 37, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.25;
  color: #313131;
  text-rendering: optimizeLegibility; }

/* line 44, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
h2 {
  margin-top: 1.5rem;
  font-size: 1.25rem; }

/* line 48, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
h3, h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem; }

/* Body text */
/* line 54, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
p {
  margin: 1rem 0 1rem 0; }

/* line 58, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
strong {
  color: #303030; }

/* Lists */
/* line 64, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 69, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
dt {
  font-weight: bold; }

/* line 72, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
dd {
  margin-bottom: .5rem; }

/* Misc */
/* line 77, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff; }

/* line 85, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase; }

/* line 91, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #e5e5e5; }

/* Code */
/* line 97, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
code,
pre {
  font-family: Menlo, Monaco, "Courier New", monospace; }

/* line 101, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
code {
  padding: .25em .5em;
  font-size: 85%;
  color: #bf616a;
  background-color: #f9f9f9;
  border-radius: 3px; }

/* line 108, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f9f9f9; }

/* line 121, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent; }

/* line 128, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
small {
  font-size: 0.875rem; }

/* line 132, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
strong > em {
  font-weight: normal;
  font-style: normal;
  background-color: #ffa; }

/* line 138, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
table {
  width: 100%; }
  /* line 141, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
  table th,
  table td {
    padding: 0;
    border: none; }
  /* line 146, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
  table th {
    font-weight: bold;
    text-align: left; }

/* line 152, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.typo.scss */
.tiny-font {
  font-size: 0.75rem; }
