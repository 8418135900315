/* line 3, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input {
  margin: 0 auto;
  max-width: 420px;
  border: 2px solid #f1f1f1;
  padding: 4px;
  border-radius: 4px; }
  /* line 53, /home/rrrene/projects/homepage_weekly/web/static/css/app/_config.scss */
  .subscribe-input:after {
    content: ".";
    display: block;
    clear: both;
    font-size: 0;
    height: 0;
    visibility: hidden; }
  /* line 10, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input label {
    display: none; }

/* line 15, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input__input {
  margin-bottom: 4px; }
  /* line 18, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input__input input[type=text] {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0.555555rem 0.888888rem;
    font-size: 1rem;
    line-height: 1.333333;
    background: #f5f5f5;
    border: 1px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 4px; }
    /* line 32, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
    .subscribe-input__input input[type=text]:focus {
      outline: none; }

/* line 38, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input2 {
  margin: 0 auto;
  max-width: 420px;
  border: 2px solid #f1f1f1;
  padding: 4px;
  border-radius: 4px; }
  /* line 53, /home/rrrene/projects/homepage_weekly/web/static/css/app/_config.scss */
  .subscribe-input2:after {
    content: ".";
    display: block;
    clear: both;
    font-size: 0;
    height: 0;
    visibility: hidden; }
  /* line 45, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input2 label {
    margin-top: 0.5rem; }

/* line 50, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input2__input {
  margin-bottom: 4px; }
  /* line 53, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input2__input input[type=text] {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0.555555rem 0.888888rem;
    font-size: 1rem;
    line-height: 1.333333;
    background: #f5f5f5;
    border: 1px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 4px; }
    /* line 67, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
    .subscribe-input2__input input[type=text]:focus {
      outline: none; }

/* line 74, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input__button .btn {
  width: 100%; }

/* line 78, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.subscribe-input2__button {
  margin-top: 8px; }

/* line 82, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.text-answer {
  color: #892ad1; }

/* line 85, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
.mark-answer {
  border: 2px solid #ba8cdd !important; }

@media screen and (min-width: 639px) {
  /* line 90, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input__input {
    float: left;
    width: 70%;
    padding-right: 4px;
    margin-bottom: 0; }
  /* line 96, /home/rrrene/projects/homepage_weekly/web/static/css/app/component.subscribe-input.scss */
  .subscribe-input__button {
    float: left;
    width: 30%; } }
