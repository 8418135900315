/* line 3, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
label,
input,
textarea {
  font-size: 1rem; }

/* line 8, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
label {
  display: block; }

/* line 12, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
textarea,
input[type=text] {
  width: 100%; }

/* line 16, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
textarea {
  min-height: 200px; }

/* line 20, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.control-label--inline {
  display: inline-block; }

/* line 23, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.form-control--inbox-item-text {
  min-height: 100px; }

/* line 28, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.ajax-form h2,
.ajax-form label {
  display: none; }

/* line 32, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.ajax-form a[data-set-url] {
  display: block; }

/* line 35, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.ajax-form textarea,
.ajax-form input[type=text] {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  border: 1px solid #f1f1f1; }

/* line 53, /home/rrrene/projects/homepage_weekly/web/static/css/app/_config.scss */
.ajax-form .form-group-thirds:after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

/* line 44, /home/rrrene/projects/homepage_weekly/web/static/css/app/base.forms.scss */
.ajax-form .form-group-thirds .form-group {
  float: left;
  width: 33%; }
